import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import InputTextarea, { InputTextareaProps } from '~/components/input/InputTextarea';

export default function ZodFieldTextarea({
  name,
  ...props
}: Omit<InputTextareaProps, 'onChange' | 'value'> & { name: string }) {
  const [prefixed, value, setValue] = useZodFormFieldSingle(name);

  return <InputTextarea {...props} name={prefixed} value={value ?? ''} onChange={(value) => setValue(value || '')} />;
}
